import React from "react"
import { Element } from "react-scroll"

import Layout from "../components/layout"
import SEO from "../components/seo"
import InternalLink from "../components/internal-link"
import ExternalLink from "../components/external-link"

const Platform = () => (
  <Layout title="2024 Resolutions" color="yellow" heroImageName="tasks">
    <SEO title="YDSA 2024 Resolutions" ogImage="tasks" />
    <div className="sidebar">
      <h2>Our Proposals</h2>
      <ul>
        <li>
          <InternalLink to="r3">
            R3. For Rechartering the Youth Labor Committee, Student Worker
            Unions, and the Rank-and-File Strategy
          </InternalLink>
        </li>
        <li>
          <InternalLink to="r5">
            R5. Towards a National Student Strike for Palestine
          </InternalLink>
        </li>
        <li>
          <InternalLink to="r12">
            R12. Responsibilities for National Coordinating Committee Members
          </InternalLink>
        </li>
        <li>
          <InternalLink to="r21">
            R21. Building Militant and Democratic Student Unions on College
            Campuses
          </InternalLink>
        </li>
        <li>
          <InternalLink to="r22">
            R22. Class Struggle Internationalism
          </InternalLink>
        </li>
        <li>
          <InternalLink to="r23">
            R23. For an Independent Youth International Committee
          </InternalLink>
        </li>
        <li>
          <InternalLink to="r24">R24. Budget Autonomy for YDSA</InternalLink>
        </li>
        <li>
          <ExternalLink href="https://docs.google.com/forms/d/e/1FAIpQLSekXOrpEBaUdC2BcvQZ_9iqtVoetHFecfkG3zUFL6hLfGRo-w/viewform">
            <strong>Sign on in support of Bread and Roses' resolutions</strong>
          </ExternalLink>
        </li>
      </ul>
    </div>

    <div className="content" id="wws">
      <p>
        Read our resolutions below and{" "}
        <ExternalLink href="https://docs.google.com/forms/d/e/1FAIpQLSekXOrpEBaUdC2BcvQZ_9iqtVoetHFecfkG3zUFL6hLfGRo-w/viewform">
          sign here
        </ExternalLink>{" "}
        to express your support!
      </p>
      <Element name="r3" id="r3">
        <h2>
          R3. For Rechartering the Youth Labor Committee, Student Worker Unions,
          and the Rank-and-File Strategy
        </h2>
        <p>
          Whereas, the working-class is the only force in society with both the
          interest and power to rupture with capitalism and build socialism. To
          realize this historical mission, the working-class must be organized
          and guided by a socialist vision for the transformation of society.
          However, the working-class today is largely disorganized, and decades
          of McCarthyite repression have separated the left from the worker’s
          movement. As socialists, our tasks are to help build a militant and
          independent worker’s movement, and to merge this worker’s movement
          with the socialist movement.
        </p>
        <p>
          Whereas, the modern labor movement is still, as a whole, fragmented
          and weak. Union density and strike actions have steadily decreased for
          the past several decades. Instead of resisting this decline, the labor
          bureaucracy is overseeing it—pursuing class collaboration over
          struggle, negotiating concessions, and restraining working-class
          self-activity. Additionally, instead of resisting the neo-liberal
          politics of both parties, the labor bureaucracy has tailed the
          capitalist establishment of the Democratic party.
        </p>
        <p>
          Whereas, in the last decade, particularly in the past few years, we
          have seen glimpses of a resurgent labor movement. Grassroots
          unionization efforts at Starbucks, Amazon, and beyond have spurred new
          and new forms of organizing. Reform caucuses like Teamsters for a
          Democratic Union and Unite All Workers for Democracy have pushed their
          unions left, executed or threatened large-scale strikes, and won
          significant contracts and material gains. On campuses, a number of
          undergraduate student worker unions and organizing drives have been—
          and continue to be— organized, many of which have been founded, led,
          and/or supported by YDSA chapters and members.
        </p>
        <p>
          In the current moment, the United Auto Workers, under the leadership
          of newly-elected president and labor-militant Shawn Fain, is
          simultaneously running new organizing drives in the South, striking
          the University of California system in support of Palestine and free
          speech, and laying the foundation for a general strike in May 2028.
        </p>
        <p>
          Whereas, a militant fighting labor movement must be rebuilt from the
          bottom-up. Owing to their structural position, occupying a space
          between the working and capitalist classes, the labor bureaucracy
          plays a role as mediators of class-struggle, restraining militancy and
          protecting the “labor peace.” Additionally, progressive unions
          staffers are constrained in their ability to reform their unions.
          Change must come from below– from the rank-and-file. Only through the
          self-activity of rank-and-file workers on the shop-floor can the labor
          movement be re-oriented towards class struggle.
        </p>
        <p>
          Whereas, YDSA adopted the “Rank-and-File Strategy” (or ‘RFS’) as our
          guiding orientation towards labor organizing at our 2022 and 2023
          conventions. RFS is a long-term strategy to rebuild a militant labor
          movement from the bottom-up and re-link that movement with the
          socialist movement through socialists organizing on the shop-floor as
          rank-and-file leaders. Rank-and-file socialist workers can build
          shop-floor power, reform their unions, spread socialist consciousness
          amongst their co-workers, and strengthen ties between their unions and
          mass movements as well as with DSA.
        </p>
        <p>
          Whereas, YDSA can serve a vital role in furthering the rank-and-file
          strategy by encouraging young socialists to enter the labor movement
          as rank-and-file organizers, and by giving them crucial organizing
          experience and training during their undergraduate education. YDSA
          members are uniquely positioned to consider re-orienting their career
          paths and committing to being lifelong socialist organizers by
          ‘industrializing’— by taking shop-floor level jobs in strategic
          industries (such as healthcare, k-12 education, and logistics). This
          past year, YDSA’s Youth Labor Committee (YLC) has started laying the
          foundations for a “rank-and-file pipeline” between YDSA and the labor
          movement by developing pro-RFS materials and trainings for chapters
          and collaborating with DSA’s Workers Organizing Workers (WOW)
          committee and the Rank-and-File Project (RFP).
        </p>
        <p>
          Whereas, organizing a student worker union is not only a feasible task
          for many YDSA chapters, but is among the most significant and
          important tasks a chapter can take on. Student worker unions create
          otherwise non-existent sites of working class power and activity for
          working students, spurring students (worker and non-worker alike) to
          become more class-conscious and pro-labor. Unionization campaigns
          themselves lead to accelerated political development and organizing
          experience for participants, which can build YDSA chapters and the
          campus left. Additionally, student worker unions form power bases
          that, through the threat of withholding labor, can enhance and empower
          other left-student demands, fight privatization and austerity, and
          transform higher education for the better.
        </p>
        <p>
          Beyond the campus, student worker unions can influence and strengthen
          the broader labor movement; Experience in a union before graduating
          encourages young workers to stay involved in the labor movement
          post-graduation— promoting industrializing, participating in union
          reform movements, and/or organizing new unions. In tandem, student
          worker unions can affiliate with larger unions and actively
          participate in them, pushing them left and providing them with young
          and experienced organizers.
        </p>
        <p>
          Be it therefore resolved, that YDSA affirms labor work as a core task
          of our organization, and will continue the work of our National Labor
          Committee (the YDSA NLC, “Youth Labor Committee”, or YLC) in
          2024-2025. The YLC shall be staffed with no fewer than three
          co-chairs, appointed by YDSA’s National Coordinating Committee, and
          shall receive consistent support from no fewer than two members of the
          NCC.
        </p>
        <p>
          Be it further resolved, the NCC and YLC will encourage well-positioned
          YDSA chapters to run labor campaigns, especially student worker
          unionization campaigns, and to build relationships with the local
          labor movement and lend strategic support to local union campaigns as
          need arises. Large and public universities shall be given additional
          attention and encouragement. The YLC will provide structured and
          ongoing training, mentorship, and support for these campaigns, and
          will be tasked with developing and cohering a base of YDSA members
          with labor experience to serve as labor organizing mentors. Building
          and maintaining a structured, one-on-0ne mentorship program shall be
          considered a core function of the committee. Furthermore, the YLC
          should continue to map labor work in YDSA and in the broader
          undergraduate student labor movement.
        </p>
        <p>
          Be it further resolved, YDSA reaffirms our commitment to the
          Rank-and-File Strategy, and will continue to build out a rank-and-file
          pipeline between YDSA and the labor movement. The YLC will develop and
          distribute political education materials promoting RFS, will conduct
          outreach to identify members interested in industrializing or salting,
          and will map the state of the Rank-and-File pipeline. Furthermore, the
          YLC will work towards identifying and building support networks—
          particularly industry cohorts— for industrializing members, with the
          aim of providing long-term support, mentorship, and space for
          strategic discussion.
        </p>
        <p>
          In this work, the YLC shall collaborate with DSA’s National Labor
          Committee (NLC), including its Workers Organizing Workers (WOW)
          program, and the Rank-and-File Project (RFP).
        </p>
        <p>
          Be it further resolved, that YDSA and the YLC should engage positively
          and proactively with non-socialist organizations rooted in the
          undergraduate labor movement, namely the Student Worker Alliance
          Network (SWAN) and the United Auto Workers’ Youth Labor Organizing
          Corps (YLOC).
        </p>
        <p>
          And be it resolved, that YDSA and the YLC shall, in mentorship,
          trainings, and wherever possible, aim to push against business and
          liberal unionist orientations, and encourage members to build
          democratic, militant, rank-and-file, class-struggle oriented unions.
          YDSA is encouraged to develop political-education materials to this
          aim, and to deepen understanding of labor politics and strategy in
          YDSA and its membership.
        </p>
      </Element>

      <Element name="r5" id="r5">
        <h2>R5. Towards a National Student Strike for Palestine</h2>
        <p>
          Whereas…Palestine and the current political moment around it is the
          most important movement in our lifetimes. As tens of thousands of
          Palestinians are getting murdered by the IOF, students across the
          country are organizing for their schools to divest from Israel in the
          largest anti-war movement since 2003.
        </p>
        <p>
          While the energy around this moment is exciting, we must look forward
          and harness it strategically. We cannot let this moment be
          self-contained or fade away. In the aftermath of the Gaza Solidarity
          Encampments in Spring 2024, campuses were left without much political
          direction, often being called out to rallies, pickets, or other
          actions with no long term strategy for escalation and no clear
          political analysis.
        </p>
        <p>
          The Gaza Solidarity Encampments of Spring 2024 were an unprecedented
          moment of a surge in student organizing. However, many were not
          successful in achieving their demands. In the places where a strong
          democratic culture did not exist, it was easier for campus
          administrations to sweep encampments and win negotiations.
        </p>
        <p>
          However, in the cases where a democratic organization was leading the
          encampment—such as San Francisco State University—students were able
          to win their demands and build a democratic organization that has
          lasted even after the students won.
        </p>
        <p>
          Chapters across the country have noted the importance of the support
          of organized labor—such as UAW 4811—and campus unions in supporting
          students and maintaining the encampment.
        </p>
        <p>
          Previous national campaigns proposed at YDSA convention have not
          supported local chapters adequately, due to a variety of factors such
          as missing the political pulse on campus, not providing the relevant
          trainings for local organizing conditions, or not doing adequate
          outreach to make chapters aware of the materials available to them.
        </p>
        <p>
          Therefore be it resolved…YDSA chapters are encouraged to organize
          democratically-run campaigns demanding their school’s divestment from
          Israel, a ceasefire in Gaza, and free speech on campus. Chapters’
          campaigns should be designed towards a National Student Strike.
        </p>
        <p>
          A Palestine Committee (PC) will be created to help local chapters
          organize their campaigns and coordinate the student strike. The
          responsibilities of the PC will be to run Organizing Trainings to
          imbue students with the organizing skills they need, create
          political-education materials for chapters running Palestine
          campaigns, and maintain a space in YDSA for open political discussion
          and debate around Palestine and YDSA’s orientation towards the issue.
        </p>
        <p>
          The Organizing Trainings will define the nature of a student strike,
          explain why a student strike is strategic in terms of YDSA winning our
          demands, and help plan an escalatory campaign timeline for chapters.
          The Organizing Trainings will also emphasize the need for building
          mass democratic organizations on their campuses to fight for these
          demands. In addition, these trainings will teach the importance of
          coalition-building and building relations with campus labor unions,
          which is an imperative relationship to have in order to carry out a
          successful student strike. As YDSA, we should be fighting for militant
          structuralism: we fight for democratic decision-making in our
          coalitions, and YDSA chapters should be ready to propose democratic
          structures for the coalitions we enter into. This is to preserve the
          longevity of the movement, which will only help us in winning our
          demands and creating mass democratic organizations on our campuses
          that will last even after the campaign ends.
        </p>
        <p>
          National YDSA will create spaces for coordinated organizing for YDSA
          chapters in public university systems. To further these cross-campus,
          system-wide relationships, National YDSA will host system/state-wide
          calls to foster relationships, coordinate actions, information-share,
          and campaign-build.
        </p>
        <p>
          National YDSA will create and print materials to send to chapters that
          explain YDSA’s perspective on Palestine and YDSA’s theory of change.
        </p>
        <p>
          The Communications Committee will be creating and posting materials
          that explain YDSA’s perspective and theory of change to the thousands
          of new student organizers that followed YDSA’s social media accounts
          during the Gaza Solidarity Encampments in Spring 2024.
        </p>
        <p>
          YDSA acknowledges that organizing for Palestinian liberation is often
          done in coalition with other organizations. Thus, we as YDSA can and
          should work in conjunction with other organizations as we
          simultaneously organize around the resolved clauses. We must work in
          the movement while pushing our political vision for Palestine, against
          student repression, and towards liberation for all.
        </p>
      </Element>

      <Element name="r12" id="r12">
        <h2>
          R12. Responsibilities for National Coordinating Committee Members
        </h2>
        <p>
          Whereas, it's crucial to build lasting connections between YDSA local
          chapters and their national leaders. This includes making sure local
          chapter members know who their elected leaders are.
        </p>
        <p>
          Whereas, being a political Leader on our YDSA NCC entails taking
          action to organize their comrades into action. This includes
          presenting a distinctly political vision for the tasks of YDSA
          leadership.
        </p>
        <p>
          Whereas, the act of leadership development is an inherently political
          one.
        </p>
        <p>
          Whereas, NCC members have set a precedent over this past year of using
          stipended time to have regular 1:1 meetings with chapters to discuss
          political questions and provide support.
        </p>
        <p>
          Therefore be it resolved, NCC members will continue to present a
          political vision and provide support for chapter development through
          regular 1:1 meetings with all active YDSA chapters. During these
          meetings, NCC members will discuss and advocate for strategies related
          to campaigns, coalition building, chapter structure, leadership
          development, political education, dues drive, and so on. They will
          also inform the representative leaders of upcoming national events and
          priorities. Each NCC member should send regular reminders to chapters
          to sign up for meetings. In order to facilitate this process, NCC
          members should create open appointment calendars on their preferred
          platforms.
        </p>
        <p>
          Therefore be it further resolved, YDSA affirms that leadership
          development is intertwined with political development, and thus, NCC
          members should act in their capacity to demonstrate what effective
          political leadership development looks like through their 1:1s. This
          includes providing political education resources, opening discussions
          around organizing strategy, and giving chapter leaders the opportunity
          to articulate their political perspectives.
        </p>
        <p>
          Therefore be it further resolved, it is up to individual chapter
          leaders to schedule appointments as they see fit, however, an
          appropriate schedule so as to keep up-to-date with national YDSA
          resources and support would be bi-weekly 30-minute meetings.
        </p>
        <p>
          Therefore be it further resolved, YDSA NCC members should also adopt
          1-3 priority OCs from HBCUs, public universities, and community
          colleges to support their growth during and after the Fall Drive.
        </p>
        <p>
          Therefore be it further resolved, YDSA NCC members should conduct
          chapter visits throughout the year to facilitate trainings,
          discussions, political education presentations, and give dues pitches
          to members. NCC members would do roughly 3+ visits to chapters outside
          their own over the course of the year and be refunded for minor travel
          expenses (under $50) such as gas or public transportation tickets from
          the YDSA committee budget. Any larger travel refund may be voted on in
          advance by the NCC.
        </p>
      </Element>

      <Element name="r21" id="r21">
        <h2>
          R21. Building Militant and Democratic Student Unions on College
          Campuses
        </h2>
        <p>
          Whereas, College and university campuses are a terrain of struggle
          alongside labor and electoral organizing with the potential to
          politicize masses of young people around the country;
        </p>
        <p>
          Whereas, Students have a degree of power on their campuses as tuition
          payers which has not been built through lasting organizations in the
          United States;
        </p>
        <p>
          Whereas, the inability of student government bodies to wield the
          collective power of students makes them ineffective in challenging
          university administrations;
        </p>
        <p>
          Whereas, student org coalitions are able to effectively pool resources
          and offer political direction, but unable to build bottom-up mass
          organizations representative of their student bodies;
        </p>
        <p>
          Whereas, Engaging in collective action and shared struggle facilitates
          rapid political development and consciousness-raising;
        </p>
        <p>
          Whereas, Millions of students across the country are being rapidly
          politicized and mobilizing against the genocide in Gaza;
        </p>
        <p>
          Whereas, YDSA, as a socialist organization, cannot become a
          majoritarian student organization on campus given the current state of
          mass political consciousness in the United States;
        </p>
        <p>
          Whereas, Student Unions, organizations of students across programs,
          departments, and schools exist around the world to channel the power
          of students on their campuses into long-term organization;
        </p>
        <p>
          Whereas, Militant and democratic student unions have secured
          significant victories internationally;
        </p>
        <p>
          Whereas, YDSA members at San Francisco State University (SFSU) won
          divestment, disclosure, and other demands by building a militant and
          openly democratic encampment across departments and schools;
        </p>
        <p>
          Be it therefore resolved, YDSA commits to building militant and
          democratic student unions on campuses across the United States;
        </p>
        <p>
          Be it further resolved, YDSA recognizes that these Student Unions
          shall not be built as coalitions of student organization leaders; but
          as independent, democratic mass organizations that focus on building
          the power of rank-and-file students;
        </p>
        <p>
          Be it further resolved, YDSA recognizes that the path to viable
          student unions in the US starts with campaigns around widely and
          deeply felt issues that build power for students on campus;
        </p>
        <p>
          Be it further resolved, The YDSA NCC will identify five campuses with
          the potential to establish a new student union in the next 3 years,
          taking into account factors to consider such as:
          <ul>
            <li>Size and development of YDSA Chapter;</li>
            <li>Success of recent YDSA/Student pressure campaigns;</li>
            <li>Political consciousness of students on campus;</li>
            <li>Existing activist organizations;</li>
            <li>Power and credibility of current student government;</li>
          </ul>
        </p>
        <p>
          Be it further resolved, The NCC will work to support these chapters
          through:
          <ul>
            <li>1:1 mentorship;</li>
            <li>Political education resources;</li>
            <li>Communications support;</li>
            <li>In-person visits from NCC member(s);</li>
            <li>
              Meetings with chapter leadership to generate buy-in and work with
              chapter leaders to bring a proposal to rank-and-file YDSA chapter
              members;
            </li>
          </ul>
        </p>
        <p>
          Be it further resolved, the NCC will cohere mentorship committees for
          each of the campuses identified consisting of:
          <ul>
            <li>Current/former chapter leaders;</li>
            <li>NCC members;</li>
            <li>Experienced student organizers;</li>
          </ul>
        </p>
        <p>
          Be it further resolved, YDSA will create political education resources
          about student unions, including a national political education event
          about student unions;
        </p>
        <p>
          Be it further resolved, YDSA will work to build relationships with
          established student unions in other countries to learn from their
          structures and share organizing strategies.
        </p>
      </Element>

      <Element name="r22" id="r22">
        <h2>R22. Class Struggle Internationalism</h2>
        <p>
          Whereas, Capitalism is a global force that exploits and oppresses
          billions of working-class people across the globe while continuing to
          enrich the capitalist class.
        </p>
        <p>
          Whereas, only the organized international working class can facilitate
          a revolutionary break from capitalism and replace it with socialism.
        </p>
        <p>
          Whereas, the main enemy is at home. Workers in the United States - the
          Belly of the Beast - have a distinct responsibility to build an
          international mass working-class anti-war anti-imperialist movement
          that can not only pause the destructive American war machine but
          destroy it entirely.
        </p>
        <p>
          Whereas, Capitalism is a system of class exploitation. An important
          part of this global economic system is the exploitation of workers and
          the subjugation of governments in the global periphery to capitalists
          in the imperial core. However, the central division in capitalism is
          between a global capitalist class and an international working class,
          not between competing nation-states. Workers in imperialist and
          imperialized countries and competing imperial powers must be united in
          their struggles against their ruling classes.
        </p>
        <p>
          Be it resolved, YDSA seeks to learn from and stand in solidarity with
          movements around the world fighting for democratic socialism against
          all states that engage in the oppression of working peoples and side
          with capital over workers, regardless of the political character of
          the government. Our main target is our own state and U.S. imperialism,
          but as consistent anti-imperialists and internationalists, our
          starting point for establishing solidarity is the rights of workers
          and all oppressed peoples, not geopolitical bargains and rivalries.
        </p>
        <p>
          Resolved, YDSA shall support countries in the global periphery in
          their struggle against US domination and fight the jingoism and
          militarism of our own ruling class against competing capitalist power.
          This is inseparable from supporting working-class movements around the
          world seeking to achieve democracy and socialism at home, at times in
          conflict with their governments and ruling classes.
        </p>
        <p>
          Resolved, YDSA will continue campaigns to divest and end all ties
          between our universities and military contractors and all other
          corporations complicit in human rights violations around the world,
          including the US-sponsored genocide in Gaza.
        </p>
        <p>
          Resolved, YDSA establishes that our primary task is not “Diplomacy”
          and apolitical contact with parties around the world, but purposeful
          and intentional internationalist organizing. Our task is to facilitate
          political relationships and learn from comrades across the world
          fighting for socialism.
        </p>
        <p>
          Resolved, we continue maintaining and putting out anti-imperialist
          materials, both in digital and written form, that highlight the
          horrors of the U.S. Empire and the complicity of both Republican and
          Democratic administrations. As well as the role of student and worker
          movements in the domestic anti-imperialist struggles.
        </p>
        <p>
          Resolved, YDSA commits to supporting democracy and rank-and-file
          control within working-class organizations—trade unions and political
          parties—and the struggle of the working class against self-serving
          bureaucratic layers within these organizations.
        </p>
      </Element>

      <Element name="r23" id="r23">
        <h2>R23. For an Independent Youth International Committee</h2>
        <p>
          Whereas, Capitalism is an international force and we, as socialists,
          are required to participate and engage in international issues in
          order to defeat it.
        </p>
        <p>
          Whereas, YDSA is an autonomous organization, functioning as a youth
          wing of DSA and the future youth wing of an independent Workers Party.
        </p>
        <p>
          Whereas, The annual YDSA convention is our highest decision-making
          body that sets our political and organizational direction. This
          includes questions of political orientation toward different
          international movements.
        </p>
        <p>
          Whereas, We, as YDSA, decide our own political priorities through
          convention, political leadership, and independent committees.
          Currently, YDSA’s international work is carried out through a
          subcommittee of the DSA International Committee, limiting the ability
          of YDSA to make direct connections with student movements and party
          youth wings around the world, removing YDSA’s international work from
          direct oversight by YDSA members and elected leaders.
        </p>
        <p>
          Whereas, engaging in anti-imperialist struggles and building a robust
          militant anti-war mass movement of workers and students is the prime
          task of US Socialists today.
        </p>
        <p>
          Whereas, in fall of last year, the IC Steering Committee voted down an
          IC-YLC statement in solidarity with striking students at the
          University of São Paolo. In September, thousands of students from
          across various departments at the University of São Paolo went on
          strike to protest faculty shortages and underfunding of public
          education. Student organizers from the University of São Paolo sent a
          request for YDSA to express solidarity with their struggle by
          releasing a public statement in support and encouraging members and
          chapters to send videos of solidarity. The IC-YLC unanimously approved
          a statement in solidarity with the striking students but the IC
          Steering Committee voted down the statement. They cited concerns that
          the faculty wasn’t in support of the strike, despite the faculty union
          publicly releasing a statement that they support and will participate
          in the strike.
        </p>
        <p>
          Whereas, in mid-April, YDSA made a popular post on multiple social
          media platforms calling for the US to not sanction or further fund war
          with Iran, in response to Iran’s retaliatory strike on Israeli
          military targets. In the aftermath, YDSA Comms Committee was told to
          not post on such international affairs without first receiving
          approval from DSA IC Steering.
        </p>
        <p>
          Whereas, the recent Campus Encampments for Palestine sparked a mass
          movement across the world for Palestinian liberation and activated
          thousands of students, teachers, and community members into action and
          radicalized them against the Status Quo capitalist ruling class.
          Limiting structures, particularly the capped membership, and the
          prolonged, multi-body appointment process, impaired the IC-YLC’s
          ability to turn into an organizing body.
        </p>
        <p>
          Resolved, the YIC will be open to all YDSA members in good standing
          via an application process as with any other YDSA committee, overseen
          by YDSA convention resolutions and the YDSA NCC.
        </p>
        <p>
          Resolved, YDSA requests that the DSA International Committee dissolve
          its IC Youth Committee, transfer its responsibilities to the YIC, and
          accept a representative from the YIC to the IC Steering Committee
          modeled after the relationship between the YDSA Labor Committee and
          DSA NLC.
        </p>
        <p>
          Resolved, the Committee will consist of 2 co-chairs, an NCC liaison,
          and an open membership body that will be set and seated by the NCC.
        </p>
        <p>
          Resolved, YIC will function independently of the current DSA IC and
          will be accountable to our national leadership and the will of the
          convention.
        </p>
        <p>
          Resolved, members are encouraged to remain involved in DSA IC and its
          subcommittees. YIC chairs will be encouraged to facilitate elections
          of subcommittee liaisons to help maintain lines of communication.
        </p>
        <p>
          Resolved, newly established YIC will be encouraged to take on the
          following tasks:
          <ul>
            <li>
              To track, oversee, and support projects related to international
              solidarity and political education on high school and college
              campuses and in our youth section broadly,
            </li>
            <li>
              To assist YDSA chapters as pertain to international solidarity and
              political education projects in coordination with the YDSA NCC,
            </li>
            <li>
              To bring statements regarding YDSA internationalist work to the
              YDSA NCC and prepare work summaries and quarterly reports for YDSA
              and DSA’s membership,
            </li>
            <li>
              To encourage and facilitate meetings between the YIC and YDSA NCC
              and the youth sections of left parties and other organizations
            </li>
            <li>
              To advise the IC on all matters related to international student
              and youth-wing organizing,
            </li>
            <li>
              To build the institutional memory of YDSA’s international work and
              regularly inform YDSA membership of ongoing internationalist
              efforts.
            </li>
            <li>
              To establish methods of working on anti-war projects that connect
              international socialist movements.
            </li>
            <li>
              Work closely with YPEC and the Communications Committee to help
              create Political Education materials about the anti-imperialist
              mass movement struggles in the U.S. and the World. And continue to
              expose the complicity of Biden and the Democrats in the ongoing
              genocide and help put forward outward-facing communications of our
              socialist analysis.
            </li>
          </ul>
        </p>
      </Element>

      <Element name="r24" id="r24">
        <h2>R24. Budget Autonomy for YDSA</h2>
        <p>
          Whereas, YDSA has chartered dozens of new chapters and grown by
          thousands of members in the past several years. YDSA chapters are
          building mass struggles on their campuses, developing lifelong
          socialist organizers, and educating classmates and the broader
          community.
        </p>
        <p>
          Whereas, YDSA is increasingly playing a leading role nationally and
          locally in the fight for Palestinian liberation and the youth and
          student labor movement;
        </p>
        <p>
          Whereas, student organizations throughout US history and around the
          world typically have control over their own budget, such as the
          Student Nonviolent Coordinating Committee (SNCC) during the US Civil
          Rights Movement and Juntos in Brazil. Many of these organizations use
          their budgets to hire staff and create paid political leadership and
          cadre positions;
        </p>
        <p>
          Whereas, since 2021, YDSA Conventions have repeatedly called for an
          expanded YDSA budget and the 2023 DSA Convention passed the YDSA
          consensus resolution which granted YDSA an expanded budget;
        </p>
        <p>
          Whereas, stipends for elected and appointed leadership have greatly
          expanded the capacity of YDSA at the national level and allowed
          members of the NCC to hold regular 1:1s with chapter leaders;
        </p>
        <p>
          Whereas, in their efforts to balance DSA’s budget, the NPC has cut
          YDSA Committee Chair stipends and considered proposals to eliminate
          NCC stipends multiple times;
        </p>
        <p>
          Whereas, the NPC has also voted to move all future YDSA national
          gatherings online. Being unable to meet in-person in the midst of the
          most significant student mobilizations in decades will have a
          disorganizing effect on YDSA and limit our ability to intervene
          nationally and locally in the Palestinian liberation movement;
        </p>
        <p>
          Whereas, YDSA has only one staffer and the NPC has freezed hiring of
          YDSA interns, down from 2 staffers and 2 interns in 2022. These staff
          positions were created after years of pressure from YDSA Conventions
          and YDSA Co-Chairs on the NPC. Decisions about when and who YDSA hires
          are ultimately made by the NPC, with limited input from YDSA.
        </p>
        <p>
          Whereas, in spite of these cuts, YDSA continues to carry out important
          work that could benefit from the increased scale and sophistication
          that an expanded staff and budget would provide;
        </p>
        <p>
          Whereas, cuts to the YDSA budget are not the fault of any individual
          or group, but instead represent a structural issue of YDSA not being
          able to control its own budget, raise its own money and collect dues,
          hire its own staff, and determine stipends for paid political
          leadership positions as it sees fit;
        </p>
        <p>
          Whereas, separate incorporation is a legal process that allows
          subsidiaries of an organization to manage their own finances and
          operations
        </p>
        <p>
          Whereas, models for separate incorporation in DSA already exist and
          many DSA chapters have their own bank accounts, hire their own staff,
          collect local dues, and launch dynamic fundraising initiatives all
          while receiving support from DSA through staffers, OrgTools, and other
          resources;
        </p>
        <p>
          Whereas, as socialists struggling in the heart of empire, we cannot
          limit our imagination and should experiment with organizational
          structures and approaches that best grow and cohere the socialist
          movement;
        </p>
        <p>
          Be it therefore resolved, the NCC will explore separate incorporation
          for YDSA based on input from the DSA's NPC and Budget and Finance
          Committee. The NCC will publish any findings from this exploration,
          including findings about the feasibility of separately incorporating
          YDSA, to YDSA membership in the first quarter of 2025.
        </p>
        <p>
          Be it further resolved, YDSA will explore establishing optional
          national dues, similar to DSA chapters’ local dues, a fundraising
          plan, and a budget. However, YDSA will continue to request its budget
          from DSA in addition to any optional dues and additional fundraising
          that may result from the incorporation exploration;
        </p>
        <p>
          Be it further resolved, YDSA’s Treasurer will deliver a financial
          report to each YDSA Convention, and financial information will be
          included in Convention materials;
        </p>
        <p>
          Be it further resolved, if the process of incorporation is developed
          and agreed upon, once sufficient funds are raised, the YDSA NCC will
          explore re-establishing stipends to previous levels and positions, and
          would be empowered to use the funds to support national and local
          organizing through hiring staff and interns, purchasing organizing
          materials, contracting artists, and other tasks mandated by the YDSA
          convention;
        </p>
        <p>
          Be it further resolved, the NCC will request to work with the NPC and
          DSA’s Budget and Finance committee to explore a plan to slowly
          transfer the funding of YDSA expenses from DSA to YDSA, depending on
          the results of the exploration;
        </p>
        <p>
          Be it further resolved, the NCC will investigate the possibility of
          increasing and improving paid political leadership in YDSA and will
          present a proposal to improve YDSA paid political leadership and a
          YDSA staffing plan at the 2025 YDSA Convention;
        </p>
        <p>
          Be it further resolved, YDSA requests that the NPC respects the
          democratic decisions made by this Convention and requests that the NPC
          works in good faith with YDSA leaders to implement this plan.
        </p>
        <h3>
          <div
            style={{
              padding: 20,
              marginTop: 50,
              border: "solid 3px black",
              textAlign: "center",
            }}
          >
            Do you support Bread and Roses' vision for YDSA?{" "}
            <ExternalLink href="https://docs.google.com/forms/d/e/1FAIpQLSekXOrpEBaUdC2BcvQZ_9iqtVoetHFecfkG3zUFL6hLfGRo-w/viewform">
              Sign Here!
            </ExternalLink>
          </div>
        </h3>
      </Element>
    </div>
  </Layout>
)

export default Platform
